var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.invoicesEnhanced.length > 0)?_c('section',{attrs:{"data-test-id":"billing-history"}},[_c('v-divider',{staticClass:"my-4"}),_c('h2',{staticClass:"section-title"},[_vm._v(" "+_vm._s(_vm.$t('settings.billing.billingHistory:heading'))+" ")]),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6"}},[_c('ax-text-field',{attrs:{"prepend-inner-icon":_vm.mdiMagnify,"aria-label":_vm.$t('settings.billing.searchBillingHistory:label'),"placeholder":_vm.$t('settings.billing.searchBillingHistory:label'),"clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('ax-table',{attrs:{"footer-props":_vm.footer,"headers":_vm.headers,"items":_vm.invoicesEnhanced,"search":_vm.search,"static-headers":_vm.staticHeaders,"options":_vm.tableState,"item-key":"id","no-data-text":_vm.$t('settings.billing.noInvoices:notification'),"show-select":"","sort-desc":""},on:{"update:options":function($event){_vm.tableState=$event}},scopedSlots:_vm._u([{key:"actions-left",fn:function(){return [_c('ax-tooltip',{attrs:{"disabled":_vm.selectedInvoices.length !== 0,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('ax-button',{attrs:{"disabled":_vm.selectedInvoices.length === 0,"mode":"secondary"},on:{"click":_vm.openModal}},[_c('v-icon',{staticClass:"mr-1",attrs:{"dense":""}},[_vm._v(_vm._s(_vm.mdiExportVariant))]),_vm._v(" "+_vm._s(_vm.$t('general.buttons.export'))+" ")],1)],1)]}}],null,false,749371930)},[_c('span',[_vm._v(_vm._s(_vm.$t('settings.billing.selectInvoicesToExport:prompt')))])])]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$d(new Date(item.date), 'default')))]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [(item.paid)?_c('v-icon',{staticClass:"green--text"},[_vm._v(_vm._s(_vm.mdiCheckboxMarkedCircle))]):_c('v-icon',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.mdiCloseCircle))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.total)+" ")])]}},{key:"item.pdf",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.pdf,"target":"_blank"}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDownload))])],1)]}}],null,false,1716930575),model:{value:(_vm.selectedInvoices),callback:function ($$v) {_vm.selectedInvoices=$$v},expression:"selectedInvoices"}}),_c('ax-modal',{model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('export-invoices-modal',{attrs:{"invoices":_vm.selectedInvoices},on:{"close-modal":_vm.closeModal}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }