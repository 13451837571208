import { computed, watch, Ref, unref } from '@vue/composition-api';
import { MaybeRef } from '@vueuse/core';
import { AxiosResponse } from 'axios';
import { FlagValue } from '@ax/feature-flag';
import { showErrorNotification } from '@ax/notifications';
import { consoleI18n } from '@ax/console-i18n';
import {
  GetBillingOrgByOrgId,
  GetBillingRatesByOrgId,
  GetSubscriptionsByOrgId,
  GetCreditCardByOrgId,
  GetInvoicesByOrgId,
  useGetBillingOrgByOrgIdQuery,
  useGetBillingRatesByOrgIdQuery,
  useGetSubscriptionsByOrgIdQuery,
  useGetCreditCardByOrgIdQuery,
  useGetInvoicesByOrgIdQuery,
  runGetBillingOrgByOrgId,
  runGetSubscriptionsByOrgId,
  runGetCreditCardByOrgId,
} from '../queries/billing.queries';
import {
  putBillingAddress,
  putCreditCard,
  putSubscription,
  postSubscription,
} from '../clients/billing.client';
import {
  BillingAddress,
  BillingAddressFieldset,
  BillingOrganization,
  LegacyRates,
  Rates,
  RateGroup,
  Rate,
  Plan,
  PlanTerm,
  Subscription,
  SubscriptionPayload,
  LegacyPlanName,
  isNonLegacyRates,
  isLegacyRates,
  PlanName,
  LegacyPlanNameLowerCase,
  RateInterval,
} from '../models/billing';

/* Calculates the cost for a given legacy billing plan and term
 * @param legacyRates - Object containing cost for all plans and terms
 * @param plan - Name of a specific plan
 * @param term - A specific billing term
 * @returns The cost in cents
 */
function getLegacyCost(
  legacyRates: LegacyRates,
  plan: `${LegacyPlanName}` = LegacyPlanName.full,
  term: `${RateInterval}` = RateInterval.year,
): number {
  let costString: string | undefined;
  let cost = 0;
  const ratePropPostfix =
    term === RateInterval.month ? '_monthly_base_rate' : '_annual_base_rate';
  if (plan === LegacyPlanName.basic) {
    costString = legacyRates[`basic${ratePropPostfix}`];
  } else if (plan === LegacyPlanName.tier3) {
    costString = legacyRates[`tier3${ratePropPostfix}`];
  } else {
    costString = legacyRates[`full${ratePropPostfix}`];
  }

  if (!costString) {
    return cost;
  }

  cost = parseFloat(costString);

  if (term === RateInterval.year) {
    // Rate is still stored as a monthly cost
    // but we are displaying it as a yearly cost
    cost *= 12;
  }

  // Multiply by 100 to align with non-legacy rate costs
  return cost * 100;
}

export function useBilling(
  orgId: MaybeRef<number>,
  auth0Milestone1FeatureFlag: Ref<FlagValue>,
  remoteControlFeatureFlag: Ref<FlagValue>,
) {
  const currentOrgId = computed(() => unref(orgId));

  const queryDefinitions = computed(() => ({
    getBillingOrg: new GetBillingOrgByOrgId({
      orgId: currentOrgId.value,
      showLoader: true,
    }),
    getBillingRates: new GetBillingRatesByOrgId({
      orgId: currentOrgId.value,
      showLoader: true,
    }),
    getSubscriptions: new GetSubscriptionsByOrgId({
      orgId: currentOrgId.value,
      showLoader: true,
    }),
    getCreditCard: new GetCreditCardByOrgId({
      orgId: currentOrgId.value,
      showLoader: true,
    }),
  }));

  const {
    data: organization,
    isLoading: billingOrgIsLoading,
    error: billingOrgError,
  } = useGetBillingOrgByOrgIdQuery(
    computed(() => queryDefinitions.value.getBillingOrg),
  );

  const reloadOrganization = () => {
    return runGetBillingOrgByOrgId(queryDefinitions.value.getBillingOrg).then(
      (response) => {
        if (response.type === 'Success') {
          return response.success;
        }
        throw response.error;
      },
    );
  };

  const {
    data: rates,
    isLoading: ratesIsLoading,
    error: billingRatesError,
  } = useGetBillingRatesByOrgIdQuery(
    computed(() => queryDefinitions.value.getBillingRates),
  );

  const {
    data: subscriptions,
    isLoading: subscriptionsIsLoading,
    error: subscriptionsError,
  } = useGetSubscriptionsByOrgIdQuery(
    computed(() => queryDefinitions.value.getSubscriptions),
  );

  const reloadSubscriptions = () => {
    return runGetSubscriptionsByOrgId(queryDefinitions.value.getSubscriptions);
  };

  const {
    data: creditCard,
    isLoading: creditCardIsLoading,
    error: creditCardError,
  } = useGetCreditCardByOrgIdQuery(
    computed(() => queryDefinitions.value.getCreditCard),
  );

  const reloadCreditCard = () => {
    return runGetCreditCardByOrgId(queryDefinitions.value.getCreditCard);
  };

  const loading = computed<boolean>(() => {
    return !!(
      billingOrgIsLoading.value ||
      ratesIsLoading.value ||
      subscriptionsIsLoading.value ||
      creditCardIsLoading.value
    );
  });

  watch(
    [billingOrgError, billingRatesError, subscriptionsError, creditCardError],
    (errors) => {
      const hasError = errors.some((error) => error !== undefined);
      if (hasError) {
        showErrorNotification(
          consoleI18n.t('general.notifications.genericPageLoadError'),
        );
      }
    },
  );

  const activeSubscription = computed<Subscription | undefined>(() => {
    if (
      subscriptions.value &&
      organization.value &&
      !organization.value.legacy_billing // Legacy billing customers don't use subscriptions
    ) {
      return subscriptions.value.find(
        (sub) => sub.organization_id === currentOrgId.value,
      );
    }
    return undefined;
  });

  const plans = computed<Plan[]>(() => {
    let computedPlans: Plan[] = [];
    if (organization.value && rates.value && subscriptions.value) {
      if (organization.value.legacy_billing && isLegacyRates(rates.value)) {
        computedPlans = getLegacyPlans(organization.value, rates.value);
      } else if (isNonLegacyRates(rates.value)) {
        computedPlans = getNonLegacyPlans(rates.value);
      }
    }

    return computedPlans;
  });

  const currentPlan = computed<Plan | undefined>(() => {
    if (organization.value && plans.value.length > 0) {
      return plans.value.find((plan) => plan.active);
    }

    return {};
  });

  const planFeatures = computed(() => ({
    [PlanName.patch]: [
      consoleI18n.t('settings.billing.planFeatures.crossOsPatching:phrase'),
      consoleI18n.t('settings.billing.planFeatures.patchAutomation:phrase'),
      consoleI18n.t('settings.billing.planFeatures.singleSignOn:phrase'),
      consoleI18n.t('settings.billing.planFeatures.standardSupport:phrase'),
    ],
    [PlanName.manage]: [
      consoleI18n.t('settings.billing.planFeatures.everythingInBasic:phrase'),
      consoleI18n.t(
        'settings.billing.planFeatures.advancedAutomationFeatures:phrase',
      ),
      consoleI18n.t('settings.billing.planFeatures.softwareManagement:phrase'),
      consoleI18n.t('settings.billing.planFeatures.worklets:phrase'),
      consoleI18n.t('settings.billing.planFeatures.thirdPartyPatching:phrase'),
      consoleI18n.t('settings.billing.planFeatures.apiAccess:phrase'),
    ],
    [PlanName.tier3]: [
      consoleI18n.t(
        'settings.billing.planFeatures.everythingInStandard:phrase',
      ),
      ...(remoteControlFeatureFlag.value
        ? [consoleI18n.t('settings.billing.planFeatures.remoteControl:phrase')]
        : []),
      consoleI18n.t(
        'settings.billing.planFeatures.automatedVulnerabilityRemediation:phrase',
      ),
      consoleI18n.t('settings.billing.planFeatures.multiZoneManagement:phrase'),
    ],
  }));

  function getLegacyPlans(
    org: BillingOrganization,
    billingRates: LegacyRates,
  ): Plan[] {
    return [
      {
        title: consoleI18n.t(
          `settings.billing.planNames:values.${PlanName.patch}`,
        ),
        subtitle: consoleI18n.t(
          `settings.billing.planDescriptions:values.${PlanName.patch}`,
        ),
        features: planFeatures.value[PlanName.patch],
        plan: LegacyPlanNameLowerCase.basic,
        term: PlanTerm.yearly,
        active: org.sub_plan === LegacyPlanName.basic,
        cost: getLegacyCost(
          billingRates,
          LegacyPlanName.basic,
          RateInterval.year,
        ),
      },
      {
        title: consoleI18n.t(
          `settings.billing.planNames:values.${PlanName.manage}`,
        ),
        subtitle: consoleI18n.t(
          `settings.billing.planDescriptions:values.${PlanName.manage}`,
        ),
        features: planFeatures.value[PlanName.manage],
        plan: LegacyPlanNameLowerCase.full,
        term: PlanTerm.yearly,
        active: org.sub_plan === LegacyPlanName.full,
        cost: getLegacyCost(
          billingRates,
          LegacyPlanName.full,
          RateInterval.year,
        ),
      },
    ];
  }

  function getNonLegacyPlans(billingRates: Rates): Plan[] {
    return Object.entries(billingRates).flatMap(
      ([typeKey, group]: [string, RateGroup]) => {
        const type: PlanName = PlanName[typeKey];
        return Object.entries(group).map(([termKey, plan]: [string, Rate]) => {
          const term: PlanTerm = PlanTerm[termKey];
          return {
            title: consoleI18n.t(`settings.billing.planNames:values.${type}`),
            subtitle: consoleI18n.t(
              `settings.billing.planDescriptions:values.${type}`,
            ),
            features: planFeatures.value[type],
            plan: type,
            term,
            deviceCount: activeSubscription.value?.quantity || 0,
            active:
              activeSubscription.value?.plan === type &&
              activeSubscription.value?.term === term,
            cost: plan.amount,
            saving:
              group.monthly && group.yearly
                ? group.monthly.amount! - group.yearly.amount! / 12
                : 0,
            nextPaymentDate: activeSubscription.value?.ends_at,
          };
        });
      },
    );
  }

  const billingAddress = computed<BillingAddress>(() => {
    if (!organization.value) {
      return {};
    }

    return {
      orgId: organization.value.id,
      name: organization.value.name,
      addr1: organization.value.addr1,
      addr2: organization.value.addr2,
      city: organization.value.city,
      state: organization.value.state,
      zipcode: organization.value.zipcode,
      country: organization.value.country,
    };
  });

  function updateBillingAddress(
    fieldsetData: BillingAddressFieldset,
  ): Promise<AxiosResponse> {
    const nextAddress = {
      ...billingAddress.value,
      addr1: fieldsetData.addressLine1,
      addr2: fieldsetData.addressLine2,
      city: fieldsetData.city,
      country: fieldsetData.country,
      name: fieldsetData.companyName,
      state: fieldsetData.state,
      zipcode: fieldsetData.zipcode,
    };

    return putBillingAddress(currentOrgId.value, nextAddress);
  }

  function updateCreditCard(token: string): Promise<AxiosResponse> {
    return putCreditCard(currentOrgId.value, token);
  }

  function updateSubscription(
    data: SubscriptionPayload,
  ): Promise<AxiosResponse> {
    const isUpdating = !!activeSubscription.value;
    const subscriptionPlan = {
      ...data,
      coupon: data.plan === PlanName.manage ? data.coupon : null,
    };
    const payload = isUpdating
      ? { ...activeSubscription.value, ...subscriptionPlan }
      : subscriptionPlan;

    if (isUpdating) {
      return putSubscription(currentOrgId.value, payload).then((response) => {
        reloadCreditCard();
        reloadSubscriptions();

        return response;
      });
    }

    return postSubscription(currentOrgId.value, payload).then((response) => {
      reloadCreditCard();
      reloadSubscriptions();

      return response;
    });
  }

  return {
    organization,
    reloadOrganization,
    billingAddress,
    rates,
    subscriptions,
    creditCard,
    activeSubscription,
    plans,
    currentPlan,
    updateBillingAddress,
    updateCreditCard,
    updateSubscription,
    loading,
  };
}

export function useInvoices(orgId: MaybeRef<number>) {
  const { data: invoices } = useGetInvoicesByOrgIdQuery(
    computed(() => new GetInvoicesByOrgId({ orgId: unref(orgId) })),
  );

  return { invoices };
}
