export const currencyPrefix = (value: string | number, prefix = '$'): string =>
  `${prefix}${value}`;

export const formatCurrency = (
  amount: string | number,
  decimalCount = 2,
  decimal = '.',
  thousands = ',',
): string | number => {
  try {
    let value = Math.abs(decimalCount);
    value = Number.isNaN(value) ? 2 : value;

    const negativeSign = amount < 0 ? '-' : '';
    const fixedValue = Math.abs(Number(amount) || 0).toFixed(value);
    const iValue = parseInt(fixedValue, 10);
    const iString = iValue.toString();
    const j = iString.length > 3 ? iString.length % 3 : 0;

    return (
      negativeSign +
      (j ? iString.substr(0, j) + thousands : '') +
      iString.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (value
        ? decimal +
          Math.abs(Number(fixedValue) - iValue)
            .toFixed(value)
            .slice(2)
        : '')
    );
  } catch (e) {
    return amount;
  }
};

export const formatCentsAsDollars = (amount: number): string =>
  currencyPrefix(formatCurrency(amount / 100));

export const formatMonthlyCentsAsDollars = (
  amount: number,
  decimalCount = 2,
): string => currencyPrefix(formatCurrency(amount / 12 / 100, decimalCount));
