import { computed, Ref } from '@vue/composition-api';
import { FlagValue } from '@ax/feature-flag';
import { consoleI18n } from '@ax/console-i18n';

export function usePlanFeatures(
  auth0Milestone1FeatureFlag: Ref<FlagValue>,
  remoteControlFeatureFlag: Ref<FlagValue>,
) {
  return computed(() => [
    {
      label: consoleI18n.t(
        'settings.billing.planFeatures.crossOsPatching:phrase',
      ),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.crossOsPatching:description',
      ),
      patch: true,
      manage: true,
      tier3: true,
    },
    {
      label: consoleI18n.t(
        'settings.billing.planFeatures.patchAutomation:phrase',
      ),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.patchAutomation:description',
      ),
      patch: true,
      manage: true,
      tier3: true,
    },
    {
      label: consoleI18n.t(
        'settings.billing.planFeatures.fullDeviceInventory:phrase',
      ),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.fullDeviceInventory:description',
      ),
      patch: true,
      manage: true,
      tier3: true,
    },
    {
      label: consoleI18n.t(
        'settings.billing.planFeatures.customNotifications:phrase',
      ),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.customNotifications:description',
      ),
      patch: true,
      manage: true,
      tier3: true,
    },
    {
      label: consoleI18n.t(
        'settings.billing.planFeatures.basicReporting:phrase',
      ),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.basicReporting:description',
      ),
      patch: true,
      manage: true,
      tier3: true,
    },
    {
      label: consoleI18n.t(
        'settings.billing.planFeatures.twoFactorAuthentication:phrase',
      ),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.twoFactorAuthentication:description',
      ),
      tooltipImage: 'tooltip-two-factor.png',
      patch: true,
      manage: true,
      tier3: true,
    },
    {
      label: consoleI18n.t('settings.billing.planFeatures.rbac:phrase'),
      tooltip: consoleI18n.t('settings.billing.planFeatures.rbac:description'),
      patch: true,
      manage: true,
      tier3: true,
    },
    ...(auth0Milestone1FeatureFlag.value
      ? []
      : [
          {
            label: consoleI18n.t(
              'settings.billing.planFeatures.singleSignOn:phrase',
            ),
            tooltip: consoleI18n.t(
              'settings.billing.planFeatures.singleSignOn:description',
            ),
            patch: true,
            manage: true,
            tier3: true,
          },
        ]),
    {
      label: consoleI18n.t('settings.billing.planFeatures.apiAccess:phrase'),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.apiAccess:description',
      ),
      patch: false,
      manage: true,
      tier3: true,
    },
    {
      label: consoleI18n.t(
        'settings.billing.planFeatures.thirdPartyPatching:phrase',
      ),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.thirdPartyPatching:description',
      ),
      patch: false,
      manage: true,
      tier3: true,
    },
    {
      label: consoleI18n.t(
        'settings.billing.planFeatures.advancedAutomationFeatures:phrase',
      ),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.advancedAutomationFeatures:description',
      ),
      patch: false,
      manage: true,
      tier3: true,
    },
    {
      label: consoleI18n.t(
        'settings.billing.planFeatures.rulesBasedPatchingEngine:phrase',
      ),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.rulesBasedPatchingEngine:description',
      ),
      patch: false,
      manage: true,
      tier3: true,
    },
    {
      label: consoleI18n.t(
        'settings.billing.planFeatures.softwareManagement:phrase',
      ),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.softwareManagement:description',
      ),
      patch: false,
      manage: true,
      tier3: true,
    },
    {
      label: consoleI18n.t('settings.billing.planFeatures.worklets:phrase'),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.worklets:description',
      ),
      patch: false,
      manage: true,
      tier3: true,
    },
    {
      label: consoleI18n.t(
        'settings.billing.planFeatures.fullSoftwareInventory:phrase',
      ),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.fullSoftwareInventory:description',
      ),
      patch: false,
      manage: true,
      tier3: true,
    },
    {
      label: consoleI18n.t(
        'settings.billing.planFeatures.betaProgramEnrollment:phrase',
      ),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.betaProgramEnrollment:description',
      ),
      patch: false,
      manage: false,
      tier3: true,
    },
    ...(remoteControlFeatureFlag.value
      ? [
          {
            label: consoleI18n.t(
              'settings.billing.planFeatures.remoteControl:phrase',
            ),
            tooltip: consoleI18n.t(
              'settings.billing.planFeatures.remoteControl:description',
            ),
            patch: false,
            manage: false,
            tier3: true,
          },
        ]
      : []),
    {
      label: consoleI18n.t(
        'settings.billing.planFeatures.automatedVulnerabilityRemediation:phrase',
      ),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.automatedVulnerabilityRemediation:description',
      ),
      patch: false,
      manage: false,
      tier3: true,
    },
    {
      label: consoleI18n.t(
        'settings.billing.planFeatures.multiZoneManagement:phrase',
      ),
      tooltip: consoleI18n.t(
        'settings.billing.planFeatures.multiZoneManagement:description',
      ),
      patch: false,
      manage: false,
      tier3: true,
    },
  ]);
}
